import React from "react";

// fix React.lazy fail chunk error using recursion
const retry = (fn, retriesLeft = 5, interval = 1000) => {
   return new Promise((resolve, reject) => {
      fn()
         .then(resolve)
         .catch((error) => {
            setTimeout(() => {
               if (retriesLeft === 1) {
                  // reject('maximum retries exceeded');
                  reject(error);
                  return;
               }
               // Passing on "reject" is the important part
               retry(fn, retriesLeft - 1, interval).then(resolve, reject);
            }, interval);
         });
   });
};

const componentLoader = (fn) => React.lazy(() => retry(fn));

export default componentLoader;
