import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "animate.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

/**
 * Redux Connection
 */
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import GlobalWrapper from "components/WrapperComponents/global-wrapper";
import MemberPointSettings from "components/WrapperComponents/MemberPointSettings";
import { TimerContextProvider } from "components/WrapperComponents/timer_context";
import RootReducer from "./modules";
import { composeWithDevTools } from "@redux-devtools/extension";

/**
 * Language
 */
import { I18nextProvider } from "react-i18next";
import i18n from "./language/i18n";
import componentLoader from "util/componentLoader";

window.React = React;

const composeEnhancers = composeWithDevTools({
   trace: true,
   traceLimit: 25,
});

if (process.env.NODE_ENV === "production") {
   console.log = () => {};
   console.error = () => {};
   console.debug = () => {};
}

export const store = createStore(
   RootReducer,
   composeEnhancers(applyMiddleware(thunk))
);

let MainApp = componentLoader(() => import("./App.jsx"));

const queryClient = new QueryClient();
ReactDOM.render(
   <GlobalWrapper>
      <MemberPointSettings>
         <I18nextProvider i18n={i18n}>
            <Provider store={store}>
               <QueryClientProvider client={queryClient}>
                  <TimerContextProvider>
                     <Suspense fallback={<></>}>
                        <MainApp />
                     </Suspense>
                  </TimerContextProvider>
               </QueryClientProvider>
            </Provider>
         </I18nextProvider>
      </MemberPointSettings>
   </GlobalWrapper>,
   document.getElementById("root")
);

reportWebVitals();
