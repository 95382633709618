import { createContext } from "react";
import { APPLICATION_CONFIG_ID } from "../../constant/applicationConfig";

export const GlobalContext = createContext({
  appId: APPLICATION_CONFIG_ID,
});

const GlobalWrapper = (props) => {
  return (
    <GlobalContext.Provider
      value={{
        appId: APPLICATION_CONFIG_ID,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalWrapper;
