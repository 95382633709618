import * as type from "./type";
// import { remove } from "lodash";

export const initialState = {
  isAlphabetSearchLoading: false,
  isPopularBrandLoading: false,
  errorMessage: "",
  popularBrands: [],
  searchResultBrands: [],
  totalAlphabetSearchCount: 0,
  selectedKeyword: "",
  totalBrandResultCountByName: 0,
  searchResultBrandsByName: [],
  totalPage: 0,
  currentPage: 0,
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.POPULAR_BRANDS_START_LOADING:
      return {
        ...state,
        isPopularBrandLoading: true,
      };

    case type.POPULAR_BRANDS_STOP_LOADING:
      return {
        ...state,
        isPopularBrandLoading: false,
      };

    case type.ALPHABET_SEARCH_START_LOADING:
      return {
        ...state,
        isAlphabetSearchLoading: true,
      };

    case type.ALPHABET_SEARCH_STOP_LOADING:
      return {
        ...state,
        isAlphabetSearchLoading: false,
      };

    case type.GET_BRANDS_BY_ALPHABET:
      return {
        ...state,
        searchResultBrands: action.payload,
      };

    case type.GET_POPULAR_BRANDS:
      return {
        ...state,
        popularBrands: action.payload,
      };

    case type.TOTAL_RESULT_COUNT:
      return {
        ...state,
        totalAlphabetSearchCount: action.payload,
      };

    case type.GET_BRANDS_BY_NAME:
      return {
        ...state,
        searchResultBrandsByName: action.payload,
      };

    case type.TOTAL_NAME_RESULT_COUNT:
      return {
        ...state,
        totalBrandResultCountByName: action.payload,
      };

    case type.SELECTED_KEYWORD:
      return {
        ...state,
        selectedKeyword: action.payload,
      };
    case type.CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case type.TOTAL_PAGE:
      return {
        ...state,
        totalPage: action.payload,
      };

    case type.BACK_TO_ZERO_LIST:
      return {
        ...state,
        searchResultBrandsByName: [],
        searchResultBrands: [],
      };

    default:
      return state;
  }
};

export default Reducer;

/**
 * Actions
 */

export const setStartPopularBrandsLoading = () => ({
  type: type.POPULAR_BRANDS_START_LOADING,
});

export const setStopPopularBrandsLoading = () => ({
  type: type.POPULAR_BRANDS_STOP_LOADING,
});

export const setStartAlphabetSearchLoading = () => ({
  type: type.ALPHABET_SEARCH_START_LOADING,
});

export const setStoptAlphabetSearchLoading = () => ({
  type: type.ALPHABET_SEARCH_STOP_LOADING,
});

export const setPopularBrands = (data = []) => ({
  type: type.GET_POPULAR_BRANDS,
  payload: data,
});

export const setBrandsByAlphabet = (data = []) => ({
  type: type.GET_BRANDS_BY_ALPHABET,
  payload: data,
});

export const setTotalResult = (data = 0) => ({
  type: type.TOTAL_RESULT_COUNT,
  payload: data,
});

export const setSelectedKeyword = (keyword) => ({
  type: type.SELECTED_KEYWORD,
  payload: keyword,
});

export const setBrandByName = (data = []) => ({
  type: type.GET_BRANDS_BY_NAME,
  payload: data,
});

export const setTotalNameSearchCount = (data = 0) => ({
  type: type.TOTAL_NAME_RESULT_COUNT,
  payload: data,
});

export const setCurrentPage = (data = 0) => ({
  type: type.CURRENT_PAGE,
  payload: data,
});
export const setTotalPage = (data = 0) => ({
  type: type.TOTAL_PAGE,
  payload: data,
});
export const setBrandsByNameListZero = () => ({
  type: type.BACK_TO_ZERO_LIST,
});
