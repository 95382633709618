import { createContext, useState } from "react"

const TimerContext = createContext(60)

const TimerContextProvider = (props) => {

    const [counter, setCounter] = useState(60);
    // State to use especially in OTP section between Auth pages
    const [isCounting, setIsCounting] = useState(false);

    const handleCounter = () => {
        // alert("Counter", counter);
        setCounter(60)
        setIsCounting(true)
        if (counter > 0) {
            setInterval(() => {
                setCounter(prev => prev - 1)
            }, 1000)
        }

        setTimeout(() => {
            setIsCounting(false)
        }, 60000);
    }

    return (
        <TimerContext.Provider value={{
            counter,
            handleCounter,
            isCounting
        }}>
            {props.children}
        </TimerContext.Provider>
    )
}

export { TimerContextProvider, TimerContext }
