import axios from "axios";
import { createContext, useState, useEffect } from "react";

export const MemberPointSettingsContext = createContext({});
// api fetcher
const fetchMemberPointSettings = () => {
  return axios.get(
    `${process.env.REACT_APP_AUTH_URL}/Auth/GetApplicationSetting?applicationConfigId=${process.env.REACT_APP_APPLICATION_CONFIG_ID}`
  );
};

const MemberPointSettings = (props) => {
  const [config, setConfig] = useState({});

  // no error handling yet!
  useEffect(() => {
    fetchMemberPointSettings().then(({ data }) => {
      setConfig(() => data);
    });
  }, []);

  return (
    <>
      <MemberPointSettingsContext.Provider value={{ config }}>
        {props.children}
      </MemberPointSettingsContext.Provider>
    </>
  );
};

export default MemberPointSettings;
