import * as type from "./type";

const initState = {
  memberLevels: [],
  memberPoint: {},
  isLoading: false,
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case type.START_MEMBER_LEVEL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case type.STOP_MEMBER_LEVEL_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case type.VIEW_ALL_MEMBER_LEVELS:
      return {
        ...state,
        memberLevels: action.payload,
      };

    case type.SET_MEMBER_POINT:
      return {
        ...state,
        memberPoint: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;

export const setLoading = () => ({
  type: type.START_MEMBER_LEVEL_LOADING,
});
export const setStopLoading = () => ({
  type: type.STOP_MEMBER_LEVEL_LOADING,
});

export const setMemberLevels = (payload) => ({
  type: type.VIEW_ALL_MEMBER_LEVELS,
  payload,
});

export const setMemberPoints = (payload) => ({
  type: type.SET_MEMBER_POINT,
  payload,
});
