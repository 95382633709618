import * as type from "./type";

const initState = {
  pointDetail: {},
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case type.GET_POINT_DETAIL:
      return {
        ...state,
        pointDetail: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;

export const setPointDetail = (payload) => ({
  type: type.GET_POINT_DETAIL,
  payload,
});
