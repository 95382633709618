import * as type from "./type";

export const initialState = {
  isLoading: true,
  isSecLoading: false,
  productsByBrand: {},
  products: [],
  product_count: 0,
  totalProductCount: 0,
};

/**
 * @param action
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.START_BRAND_LOADING:
      return {
        ...getCommonState(state),
        isLoading: true,
      };
    case type.START_BRAND_SECONDARY_LOADING:
      return {
        ...getCommonState(state),
        isSecLoading: true,
      };
    case type.STOP_BRAND_LOADING:
      return {
        ...getCommonState(state),
        isLoading: false,
      };
    case type.STOP_BRAND_SECONDARY_LOADING:
      return {
        ...getCommonState(state),
        isSecLoading: false,
      };
    case type.PRODUCT_BY_BRAND: {
      return {
        ...getCommonState(state),
        productsByBrand: action.payload,
        products: [...state.products, ...action.payload.products],
        totalProductCount: action.payload.total_Results,
      };
    }
    case type.PRODUCT_COUNT_BRAND: {
      return {
        ...getCommonState(state),
        product_count: state.product_count + action.payload,
      };
    }
    case "RESET_PRODUCT": {
      return {
        ...getCommonState(state),
        products: action.payload,
      };
    }
    default:
      return state;
  }
};
export default Reducer;

const getCommonState = (state) => ({
  ...state,
});

/**
 * Action
 */
export const set_start_loading = () => ({
  type: type.START_BRAND_LOADING,
});

export const set_start_sec_loading = () => ({
  type: type.START_BRAND_SECONDARY_LOADING,
});

export const set_stop_loading = () => ({
  type: type.STOP_BRAND_LOADING,
});

export const set_stop_sec_loading = () => ({
  type: type.STOP_BRAND_SECONDARY_LOADING,
});

export const set_product_by_brand = (payload) => ({
  type: type.PRODUCT_BY_BRAND,
  payload,
});

export const set_product_count = (count = 0) => ({
  type: type.PRODUCT_COUNT_BRAND,
  payload: count,
});

export const reset_products = () => ({
  type: "RESET_PRODUCT",
  payload: [],
});
