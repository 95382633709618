import {
  REMOVE_SEARCH_BRANDS,
  SET_SEARCH_BY_BRANDS,
  START_LOADING,
  STOP_LOADING,
} from "./type";

const initState = {
  brands: [],
  isLoading: false,
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case SET_SEARCH_BY_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case REMOVE_SEARCH_BRANDS:
      return {
        ...state,
        brands: [],
      };

    default:
      return state;
  }
};

export default Reducer;

export const setBrands = (payload) => ({
  type: SET_SEARCH_BY_BRANDS,
  payload,
});

export const startLoading = () => ({
  type: START_LOADING,
});

export const stopLoading = () => ({
  type: STOP_LOADING,
});

export const removeBrands = () => ({
  type: REMOVE_SEARCH_BRANDS,
});
