import {
  SET_NORMAL_ORDER,
  SET_ORDERDETAIL_FOR_APPROVAL,
  START_LOADING,
  STOP_LOADING,
} from "./type";

const initState = {
  isLoading: false,
  orderDetail: {},
  OrderDetailNormal : {},
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case SET_ORDERDETAIL_FOR_APPROVAL:
      return {
        ...state,
        orderDetail: action.payload,
      };
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case SET_NORMAL_ORDER:
      return {
        ...state,
        OrderDetailNormal: action.payload,
      }
    default:
      return state;
  }
};

export default Reducer;

export const setOrderDetailForApproval = (payload) => ({
  type: SET_ORDERDETAIL_FOR_APPROVAL,
  payload,
});

export const setStartLoading = () => ({
  type: START_LOADING,
});
export const setStopLoading = () => ({
  type: STOP_LOADING,
});

export const setOrderDetailNormal = (payload) => ({
  type: SET_NORMAL_ORDER,
  payload,
})
