import { SET_PAYBACK_PRODUCTS } from "./type";

const initState = {
  payBackProducts: [],
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case SET_PAYBACK_PRODUCTS:
      return {
        ...state,
        payBackProducts: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;

export const setPayBackProducts = (payload) => ({
  type: SET_PAYBACK_PRODUCTS,
  payload,
});
