import * as type from "./type";

const initState = {
    isMainLoading: false,
    isSecLoading: false,
    isError: false,
    subCategoryList: {},
    productCount: 0,
    productList: [],
}

const Reducer = (state = initState, action) => {
    switch (action.type) {
        case type.START_LOADING:
            return {
                ...state,
                isMainLoading: true,
            }
        case type.STOP_LOADING:
            return {
                ...state,
                isMainLoading: false
            }
        case type.START_SECONDARY_LOADING:
            return {
                ...state,
                isSecLoading: true,
            }
        case type.STOP_SECONDARY_LOADING:
            return {
                ...state,
                isSecLoading: false,
            }
        case type.IS_ERROR:
            return {
                ...state,
                isError: true
            }
        case type.SET_SUB_CATEGORY:
            return {
                ...state,
                subCategoryList: action.payload
            }
        case type.GET_PRODUCT_SEARCH_COUNT:
            return {
                ...state,
                productCount: action.payload,
            }
        case type.GET_PRODUCT_SEARCH_LIST:
            return {
                ...state,
                productList: action.payload,
            }
        case type.CLEAR_DATA_LIST:
            return {
                ...state,
                subCategoryList: {},
                productList : [],
                productCount: 0
            }
        default:
            return state;
    }
}

export default Reducer;

export const setStartMainLoading = () => ({
    type: type.START_LOADING,
})

export const setStopMainLoading = () => ({
    type: type.STOP_LOADING,
})

export const setStartSecLoading = () => ({
    type: type.START_SECONDARY_LOADING
})

export const setStopSecLoading = () => ({
    type: type.STOP_SECONDARY_LOADING,
})

export const setIsError = () => ({
    type: type.IS_ERROR,
})

export const setSubCategoryList = (payload) => ({
    type: type.SET_SUB_CATEGORY,
    payload,
})

export const setProductCount = (payload) => ({
    type: type.GET_PRODUCT_SEARCH_COUNT,
    payload,
})

export const setProductList = (payload) => ({
    type: type.GET_PRODUCT_SEARCH_LIST,
    payload,
})

export const clearDataList = () => ({
    type: type.CLEAR_DATA_LIST,
})